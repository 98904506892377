import React from "react"
import Metadata from "../components/Metadata/Metadata"
import { graphql } from "gatsby"
import LayoutWeblogSingle from "../layout/LayoutWeblogSingle"
import PaginationWeblog from "../components/Pagination/PaginationWeblog"
import Heading from "../components/Heading/Heading"
import { frontmatterPathBuilder } from "../helpers/pathBuilders"
import Aside from "../components/Aside/AsideWeblogSingle"

export const query = graphql`
    query($relativeDirectory: String) {
        markdownRemark(frontmatter: { pathName: { eq: $relativeDirectory } }) {
            html
            timeToRead
            frontmatter {
                title
                description
                date
                tags
            }
        }
    }
`

export default ({ data, pageContext }) => {
    const page = data.markdownRemark
    const { prev, next } = pageContext
    const Main = () => <div dangerouslySetInnerHTML={{ __html: page.html }} />

    return (
        <>
            <Metadata title={page.frontmatter.title} />
            <LayoutWeblogSingle
                heading={
                    <Heading
                        headingLevel="1"
                        headingTitle={frontmatterPathBuilder(page).title}
                        headingDescription={
                            frontmatterPathBuilder(page).description
                        }
                    />
                }
                pagination={<PaginationWeblog prev={prev} next={next} />}
                main={<Main />}
                aside={
                    <Aside
                        date={page.frontmatter.date}
                        tags={page.frontmatter.tags}
                        readTime={page.timeToRead}
                    />
                }
            />
        </>
    )
}
