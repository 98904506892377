import React from "react"
import kebabCase from "lodash/kebabCase"
import styles from "./Aside.module.scss"

export default props => {
    const TagLister = () =>
        props.tags.map(tag => (
            <li className={styles.aside__tags_list_item} key={tag}>
                <a
                    href={kebabCase(tag)}
                    className={`${styles.aside__link} ${styles.aside__link____tag}`}
                    key={tag}
                >
                    {tag}
                </a>
            </li>
        ))
    const Tags = () =>
        props.tags ? (
            <li className={styles.aside__list_item} key="tags">
                <span>
                    <span className={styles.aside__list_item_header}>
                        Tags:
                    </span>{" "}
                    <ul
                        className={`${styles.aside__list} ${styles.aside__tags_list}`}
                    >
                        {TagLister(props.tags)}
                    </ul>
                </span>
            </li>
        ) : null
    const Date = () =>
        props.date ? (
            <li className={styles.aside__list_item} key="date">
                <p>
                    <span className={styles.aside__list_item_header}>
                        Published:
                    </span>{" "}
                    {props.date}
                </p>
            </li>
        ) : null
    const Time = () =>
        props.readTime ? (
            <li className={styles.aside__list_item} key="date">
                <p>
                    <span className={styles.aside__list_item_header}>
                        Time to read:
                    </span>{" "}
                    {props.readTime} minutes
                </p>
            </li>
        ) : null

    return (
        <aside className={`${styles.aside____work}`}>
            <ul className={styles.aside__list}>
                <Date />
                <Time />
                <Tags />
            </ul>
        </aside>
    )
}
