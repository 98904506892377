import React from "react"
import { motion } from "framer-motion"
import styles from "./Pagination.module.scss"
import { Link } from "gatsby"
import {
    pagePathBuilder,
    frontmatterPathBuilder,
} from "../../helpers/pathBuilders"
import ArrowSimple from "../../images/arrow_simple.svg"
import ArrowReturn from "../../images/arrow_return.svg"
import { variants } from "../../helpers/motionVariants"

export default props => {
    const { prev, next } = props

    const ReturnPrevious = () => (
        <Link
            to="/weblog/"
            className={`${styles.pagination__link} ${styles.pagination__link____previous}`}
        >
            <ArrowReturn className={styles.pagination__arrow} />
            <div className={styles.pagination__text}>
                <div className={styles.pagination__label}>Return to:</div>
                <div className={styles.pagination__title}>
                    <span>Weblog Index</span>
                </div>
            </div>
        </Link>
    )

    const ReturnNext = () => (
        <Link
            to="/weblog/"
            className={`${styles.pagination__link} ${styles.pagination__link____next}`}
        >
            <div className={styles.pagination__text}>
                <div className={styles.pagination__label}>Return to:</div>
                <div className={styles.pagination__title}>
                    <span>Weblog Index</span>
                </div>
            </div>
            <ArrowReturn className={styles.pagination__arrow} />
        </Link>
    )

    const Previous = () => (
        <Link
            to={pagePathBuilder(prev)}
            className={`${styles.pagination__link} ${styles.pagination__link____previous}`}
        >
            <ArrowSimple className={styles.pagination__arrow} />

            <div className={styles.pagination__text}>
                <div className={styles.pagination__label}>Previous Post:</div>
                <div className={styles.pagination__title}>
                    {frontmatterPathBuilder(prev).title}
                </div>
            </div>
        </Link>
    )

    const Next = () => (
        <Link
            to={pagePathBuilder(next)}
            className={`${styles.pagination__link} ${styles.pagination__link____next}`}
        >
            <div className={styles.pagination__text}>
                <div className={styles.pagination__label}>Next Post:</div>
                <div className={styles.pagination__title}>
                    {frontmatterPathBuilder(next).title}
                </div>
            </div>
            <ArrowSimple className={styles.pagination__arrow} />
        </Link>
    )

    return (
        <motion.nav
            className={styles.pagination}
            variants={variants}
            initial="hidden"
            animate="enter"
        >
            <div className={styles.pagination__inner}>
                {prev ? <Previous /> : <ReturnPrevious />}
                {next ? <Next /> : <ReturnNext />}
            </div>
        </motion.nav>
    )
}
